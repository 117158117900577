<template>
    <div class="row">
        <div class="col-md-12">
            <b-card-code no-body>

              <div class="row pr-1">
                <div class="col-md-8">
                  <button v-if="checkPermission('clear sob')"  @click="clearSob()" class="btn btn-warning btn-sm ml-1" data-toggle="tooltip" data-placement="top" title="Reset Auction">
                    Reset SOB
                  </button>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control form-control-sm mb-1" v-model="search" placeholder="Search commodity code...">
                </div>
              </div>

              <div class="table-responsive">
                <table class="table b-table table-striped table-hover border">
                  <thead>
                    <tr>
                      <th width="25%" style="cursor: pointer;" @click="sortData()">
                        Auction
                        <svg v-if="sortType == 'asc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                        </svg>
                        <svg v-if="sortType == 'desc'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </th>
                      <th>SOB</th>
                      <th>Min Price</th>
                      <th>Max Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="auction in auctions" :key="auction.uuid" v-bind:id="'auction_' + auction.uuid">
                      <td class="pl-1">{{ auction.commodity.code }} - {{ auction.warehouse.name }} ({{ auction.date }})</td>
                      <td>
                        <div class="form-group mt-1">
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text">USD</span>
                            </div>
                            <input type="text" min="1" class="form-control form-control-sm" v-model="auction.sob">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group mt-1">
                          <div class="input-group input-group-sm">
                              <div class="input-group-prepend" v-if="['fixed'].includes(auction.limit_price_decrease_type)">
                              <span class="input-group-text">USD</span>
                              </div>
                              <input type="text" class="form-control" v-model="auction.limit_price_decrese" placeholder="Limit Price Decrease">
                              <div class="input-group-append" v-if="['percent'].includes(auction.limit_price_decrease_type)">
                              <span class="input-group-text">%</span>
                              </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group mt-1">
                          <div class="input-group input-group-sm">
                              <div class="input-group-prepend" v-if="['fixed'].includes(auction.limit_price_increase_type)">
                                <span class="input-group-text">USD</span>
                              </div>
                              <input type="text" class="form-control" v-model="auction.limit_price_increase" placeholder="Limit Price Increase">
                              <div class="input-group-append" v-if="['percent'].includes(auction.limit_price_increase_type)">
                                <span class="input-group-text">%</span>
                              </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-success" @click="updateSingleAuction(auction)">
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </b-card-code>
        </div>
    </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import moment from 'moment'

export default {
  title () {
    return `SOB Price`
  },
  components: {
    BCard,
    BCardText,
    BCardCode,
  },
  data() {
    return {
        result: {},
        auctions: [],
        sortType: 'asc',
        search: ''
    }
  },
  mounted() {
    this.loadAuctionData();
  },
  watch: {
    search: function (newSearch, oldSearch) {
      this.auctions = this.filterByValue(this.result.data, newSearch)
    }
  },
  methods: {
    clearSob() {

      this.$swal({
        title: 'Are you sure?',
        text: "All auction SOB will set to 0!",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('/admin/reset-sob/')
          .then(response => {

            this.loadAuctionData()
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: "Reset SOB success.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

    },
    addAnimationTableLot(uuid) {
      setTimeout(function() {
        var element = document.getElementById("auction_" + uuid)
        element.classList.add("NewDataFadeIn")

        setTimeout(function() {
          element.classList.remove("NewDataFadeIn")
        }, 2000);
      }, 200);
    },
    filterByValue(array, string) {
        return array.filter(o =>
            Object.keys(o).some(k => o.commodity.code.toLowerCase().includes(string.toLowerCase())));
    },
    sortData() {
      if (this.sortType == 'desc') {
        this.sortType = 'asc';
      } else {
        this.sortType = 'desc';
      }

      if (this.sortType == 'asc') {
        this.auctions.sort(this.compareAsc)
      } else {
        this.auctions.sort(this.compareDesc)
      }
    },
    loadAuctionData() {
        var queryParams = {}
        queryParams.per_page = 100;
        queryParams.start_at_daterange = moment().format('DD/MM/YYYY');
        queryParams.start_at_daterange += '-' + moment().add(1, 'years').format('DD/MM/YYYY');

        this.$http.get('/admin/auction', {
            params: queryParams
        })
        .then(response => {
            this.result = response.data.data
            this.auctions = response.data.data.data
            this.result.data.sort(this.compareAsc)
        })
    },
    compareAsc( obj1, obj2 )
    {
        if ( obj1.commodity.code < obj2.commodity.code ){
          return -1;
        }
        if ( obj1.commodity.code > obj2.commodity.code ){
          return 1;
        }
        return 0;
    },
    compareDesc( obj1, obj2 )
    {
        if ( obj1.commodity.code > obj2.commodity.code ){
          return -1;
        }
        if ( obj1.commodity.code < obj2.commodity.code ){
          return 1;
        }
        return 0;
    },
    updateSingleAuction(auction){
      this.$http.patch('/admin/auction/' + auction.uuid, this.prepareData(auction))
      .then(response => {

        // this.loadAuctionData()
        successNotification(this, 'Success', 'Auction updated!')

        this.addAnimationTableLot(response.data.data.uuid);

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    prepareData(auction) {
      var times = []
      auction.times.forEach(function(time){
        times.push({
          start_at: time.start_at_time_only,
          break_at: time.break_at_time_only,
          break_finish_at: time.break_finish_at_time_only,
          end_at: time.end_at_time_only,
          id: time.id,
        })
      })

      auction = {
        commodity_slug: auction.commodity.slug,
        warehouse_slug: auction.warehouse.slug,
        sob: auction.sob,
        settlement_days: auction.settlement_days,
        settlement_time: auction.settlement_time,
        is_settlement_only_weekday: auction.is_settlement_only_weekday,
        is_automatic_settlement: auction.is_automatic_settlement,
        trading_algorithm: auction.trading_algorithm,
        restricted_to_trade: auction.restricted_to_trade,
        price_movement: auction.price_movement,
        margin_minimum_for_buy_type: auction.margin_minimum_for_buy_type,
        margin_minimum_for_buy: auction.margin_minimum_for_buy,
        buyer_penalty_type: auction.buyer_penalty_type,
        buyer_penalty_amount: auction.buyer_penalty_amount,
        seller_compensation_type: auction.seller_compensation_type,
        seller_compensation_amount: auction.seller_compensation_amount,
        margin_for_sell_type: auction.margin_for_sell_type,
        margin_for_sell_amount: auction.margin_for_sell_amount,
        seller_penalty_type: auction.seller_penalty_type,
        seller_penalty_amount: auction.seller_penalty_amount,
        buyer_compensation_type: auction.buyer_compensation_type,
        buyer_compensation_amount: auction.buyer_compensation_amount,
        limit_price_increase_type: auction.limit_price_increase_type,
        limit_price_decrease_type: auction.limit_price_decrease_type,
        limit_price_increase: auction.limit_price_increase,
        limit_price_decrese: auction.limit_price_decrese,
        minimum_lot_transaction: auction.minimum_lot_transaction,
        lot_tolerance: auction.lot_tolerance,
        buyer_fee_type: auction.buyer_fee_type,
        buyer_fee_amount: auction.buyer_fee_amount,
        buyer_fee_payment_on: auction.buyer_fee_payment_on,
        seller_fee_type: auction.seller_fee_type,
        seller_fee_amount: auction.seller_fee_amount,
        seller_fee_payment_on: auction.seller_fee_payment_on,
        vat_amount: auction.vat_amount,
        repeat_type: auction.repeat_type,
        repeat_at: auction.repeat_at,
        repeat_skip_weekend_holiday: auction.repeat_skip_weekend_holiday,
        repeat_warehouses: auction.repeat_warehouses,
        date: auction.date,
        times: times,
        custom_fees: auction.custom_fees,
      }

      auction.repeat_skip_weekend_holiday = auction.repeat_skip_weekend_holiday ? 1 : 0;
      auction.is_settlement_only_weekday = auction.is_settlement_only_weekday ? 1 : 0;
      auction.restricted_to_trade = auction.restricted_to_trade ? 1 : 0;
      auction.is_automatic_settlement = auction.is_automatic_settlement ? 1 : 0;

      // Convert comma (,) to point (.)
      var convertDecimals = [
        'sob',
        'price_movement',
        'margin_minimum_for_buy',
        'buyer_penalty_amount',
        'seller_compensation_amount',
        'margin_for_sell_amount',
        'seller_penalty_amount',
        'buyer_compensation_amount',
        'limit_price_increase',
        'limit_price_decrese',
        'lot_tolerance',
        'vat_amount',
      ];
      convertDecimals.forEach(key => {
        auction[key] = String(auction[key]).replace(/,/g, '.')
        if (String(auction[key]) == 'undefined' || String(auction[key]) == 'null') {
          auction[key] = '';
        }
      });

      if (auction.settlement_days > 0) {
        auction.settlement_time = ''
      }

      return auction;
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
}
</script>

<style>
@keyframes FadeIn {
  from {
    background-color: #89ddaf;
  }

  to {
    background-color: white;
  }
}

.NewDataFadeIn {
    background-color: white;
    animation: FadeIn 1s ease-in-out forwards;
}
</style>
